import { FC, ReactNode } from 'react'

import Table from '@/components/Table'

import {
  GroupImages,
  Heading,
  Image,
  List,
  Paragraph
} from '@/contents/Network/subcomponents'
import HeadingProps from '@/contents/Network/subcomponents/HeadingProps.d'

interface TagProps {
  type: string
  id: string
  items: string | any[] | { [key: string]: any }
}

const Tag: FC<TagProps> = ({ type, id, items }) => {
  switch (type) {
    case 'p':
      return <Paragraph text={items as string} />
    case 'images':
      return <GroupImages images={items as any[]} id={id} />
    case 'image':
      return <Image id={id} name={items as string} />
    case 'h3':
      return (
        <Heading
          level={type as string}
          text={(items as HeadingProps).label}
          id={(items as HeadingProps).id}
        />
      )
    case 'h5':
    case 'h4':
      return <Heading level={type as string} text={items as string} id={id} />

    case 'ul':
    case 'ol':
      return (
        <List id={id} type={type} items={items as (string | ReactNode)[]} />
      )
    case 'table':
      return <Table items={items as []} />
    default:
      return <div>{items as string}</div>
  }
}
export default Tag
